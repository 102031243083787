import { useEffect, useRef, useState } from "react";

import Error from "next/error";
import Head from "next/head";

import Connection from "../../components/home/connection";
import HomeGameTypes from "../../components/home/homegametypes";
import HomeCarousel from "../../components/home/homecarousel";
import HomeContacts from "../../components/home/homecontacts";
import HomeCustomBlock from "../../components/home/homecustomblock";
import HomeEnrollment from "../../components/home/homeenrollment";
import HomeStats from "../../components/home/homestats";
import HomeTournament from "../../components/home/hometournament";
import Intro from "../../components/home/intro";
import Invitation from "../../components/home/invitation";
import Layout from "../../components/layout";
import Partners from "../../components/home/partners";
import Reviews from "../../components/home/reviews";
import ScheduleDateSelector from "../../components/home/scheduledateselector";
import ScheduleGameTypeSelector from "../../components/home/schedulegametypeselector";
import Schedule from "../../components/home/schedule";

import { fetchArchivedGamesForHome, fetchEnrollmentGamesForHome, fetchRunningGames } from "../../lib/api/games";
import { fetchHomePageProps, fetchAnnouncements } from "../../lib/api/content";
import { getEnrollmentGamesInitialData } from "../../lib/games";
import HomeAnnouncedGames from "../../components/home/homeannouncedgames";
import HomeTournament3 from "../../components/home/hometournament3";
import HomeBirthdayPeople from "../../components/home/homebirthdaypeople";
import { useRouter } from "next/router";
import HomePromoGame from "../../components/home/homepromogame";
import HomeDronovCup from "../../components/home/homedronovcup";

export default function Home({
    errorCode,
    layoutProps,
    currentCity,
    home_blocks,
    snippets,
    home_carousel,
    invitation_prices,
    corporate_enrollment_link,
    connection_images,
    running_games,
    enrollment_dates,
    enrollment_places,
    enrollment_game_types,
    enrollment_game_levels,
    enrollment_host_levels,
    enrollment_games,
    enrollment_matrix,
    archived_games,
    birthday_people,
    home_stats,
    current_tournament,
    current_tournaments3,
    partners,
    reviews,
    contacts,
    game_types,
    announcements,
    promo_game,
    announced_dates,
    announced_game_types,
    announced_games,
    home_custom_block,
    dronov_cup_leaders,
    initialSelectedDate,
    initialSelectedGameType,
    initialPlace,
    initialGameLevel,
    initialSelectedHostLevel,
}) {
    const [homeAnnouncements, setHomeAnnounces] = useState(announcements);
    const [runningGames, setRunningGames] = useState(running_games);
    const [selectedEnrollmentDate, setSelectedEnrollmentDate] = useState(initialSelectedDate);
    const [selectedEnrollmentGameType, setSelectedEnrollmentGameType] = useState(initialSelectedGameType);
    const [selectedEnrollmentPlace, setSelectedEnrollmentPlace] = useState(initialPlace);
    const [selectedEnrollmentGameLevel, setSelectedEnrollmentGameLevel] = useState(initialGameLevel);
    const [selectedEnrollmentHostLevel, setSelectedEnrollmentHostLevel] = useState(initialSelectedHostLevel);
    const [selectedArchiveGameType, setSelectedArchiveGameType] = useState(initialSelectedGameType);
    const [enrollmentGames, setEnrollmentGames] = useState(enrollment_games);
    const [archivedGames, setArchivedGames] = useState(archived_games);
    const router = useRouter();

    const getFilterValues = () => {
        return {
            date: enrollment_dates.length > 0 ? enrollment_dates[selectedEnrollmentDate].date : "",
            place: enrollment_places.length > 0 ? enrollment_places[selectedEnrollmentPlace].id : "",
            gameType: enrollment_game_types.length > 0 ? enrollment_game_types[selectedEnrollmentGameType].id : "",
            gameLevel: enrollment_game_levels.length > 0 ? enrollment_game_levels[selectedEnrollmentGameLevel].id : "",
            hostLevel: enrollment_host_levels.length > 0 ? enrollment_host_levels[selectedEnrollmentHostLevel].id : "",
        };
    };

    const refreshAnnouncements = async () => {
        setHomeAnnounces(await fetchAnnouncements());
    };
    const refreshRunningGames = async () => {
        setRunningGames(await fetchRunningGames(currentCity.slug));
    };
    const refreshArchivedGames = async () => {
        const gameType = enrollment_game_types.length > 0 ? enrollment_game_types[selectedArchiveGameType].id : "";
        setArchivedGames(await fetchArchivedGamesForHome(currentCity.slug, gameType));
    };
    const refreshSchedule = async () => {
        setSelectedArchiveGameType(selectedEnrollmentGameType);
        const { date, place, gameType, gameLevel, hostLevel } = getFilterValues();
        setEnrollmentGames(
            await fetchEnrollmentGamesForHome(currentCity.slug, date, gameType, hostLevel, place, gameLevel)
        );
    };

    const isInitialMount = useRef(true);
    const isInitialArchiveMount = useRef(true);

    useEffect(() => {
        // Запускаем обновление расписания только в случае интерактивного выбора даты.
        // При первоначальной загрузке страницы расписание уже заполнено нормально.
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        refreshSchedule();

        if (window && window.location && window.history) {
            const { date, place, gameType, gameLevel } = getFilterValues();
            const searchParams = new URLSearchParams();
            if (date != "" || !date.startsWith("1970-01")) {
                searchParams.set("date", date.toString());
            }
            if (place != "") {
                searchParams.set("place", place.toString());
            }
            if (gameType != "") {
                searchParams.set("game_type", gameType.toString());
            }
            if (gameLevel != "") {
                searchParams.set("game_level", gameLevel.toString());
            }
            if (searchParams.size > 0) {
                router.replace(`${window.location.pathname}?${searchParams.toString()}`, undefined, {
                    shallow: true,
                    scroll: false,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedEnrollmentDate,
        selectedEnrollmentHostLevel,
        selectedEnrollmentGameType,
        selectedEnrollmentPlace,
        selectedEnrollmentGameLevel,
    ]);

    useEffect(() => {
        if (isInitialArchiveMount.current) {
            isInitialArchiveMount.current = false;
            return;
        }
        refreshArchivedGames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedArchiveGameType]);

    if (errorCode) {
        return <Error statusCode={errorCode} />;
    }

    const enrollmentScheduleTitle =
        enrollment_dates.length > 0 ? enrollment_dates[selectedEnrollmentDate].title_date : "сегодня";

    const RenderBlock = ({ block }) => {
        switch (block) {
            case "intro":
                return (
                    <div className="dark-plate">
                        <div style={{ height: "1px" }} />
                        <Intro snippets={snippets} currentCity={currentCity} />
                    </div>
                );
            case "carousel":
                return <HomeCarousel images={home_carousel} />;
            case "invitation":
                return (
                    <Invitation
                        prices={invitation_prices}
                        snippets={snippets}
                        corporateEnrollmentLink={corporate_enrollment_link}
                        currentCity={currentCity}
                    />
                );
            case "stats":
                return <HomeStats stats={home_stats} />;
            case "game_types":
                if (birthday_people !== null) {
                    return <HomeBirthdayPeople birthdayPeople={birthday_people} />;
                }
                return (
                    <HomeGameTypes
                        types={game_types}
                        showOtherGames
                        showHeading
                        showDetails
                        currentCity={currentCity}
                    />
                );
            case "connection":
                return <Connection images={connection_images} contacts={contacts} />;
            case "tournament":
                return (
                    current_tournament && (
                        <HomeTournament
                            tournament={current_tournament.tournament}
                            leagues={current_tournament.leagues}
                            awards={current_tournament.awards}
                            ratings={current_tournament.top_ratings}
                            currentCity={currentCity}
                        />
                    )
                );
            case "tournament3":
                return <HomeTournament3 tournaments={current_tournaments3} />;
            case "announcements":
                return (
                    <>
                        {homeAnnouncements
                            .filter((announcement) => announcement.game)
                            .map((announcement, i) => (
                                <Schedule
                                    title={announcement.title}
                                    games={[announcement.game]}
                                    currentCity={currentCity}
                                    showPlacesLeftInfo
                                    showFormula
                                    isGamesList
                                    onGameChange={refreshAnnouncements}
                                    key={i}
                                />
                            ))}
                        {announced_games && announced_games.length > 0 && (
                            <HomeAnnouncedGames
                                announcedGames={announced_games}
                                announcedDates={announced_dates}
                                announcedGameTypes={announced_game_types}
                                currentCity={currentCity}
                                onGameChange={refreshAnnouncements}
                            />
                        )}
                    </>
                );
            case "promo_game":
                return <HomePromoGame promoGame={promo_game} />;
            case "running_games":
                return (
                    <Schedule
                        title="Сейчас идут"
                        games={runningGames}
                        currentCity={currentCity}
                        showPlacesLeftInfo
                        showFormula
                        isGamesList
                        onGameChange={refreshRunningGames}
                    />
                );
            case "enrollment_games":
                const dateSelector = (
                    <ScheduleDateSelector
                        gameTypes={enrollment_game_types}
                        dates={enrollment_dates}
                        places={enrollment_places}
                        gameLevels={enrollment_game_levels}
                        hostLevels={enrollment_host_levels}
                        enrollmentMatrix={enrollment_matrix}
                        selectedGameType={selectedEnrollmentGameType}
                        selectedHostLevel={selectedEnrollmentHostLevel}
                        selectedDate={selectedEnrollmentDate}
                        selectedPlace={selectedEnrollmentPlace}
                        selectedGameLevel={selectedEnrollmentGameLevel}
                        city={currentCity}
                        showEnrollmentLink
                        onGameTypeSelect={setSelectedEnrollmentGameType}
                        onPlaceSelect={setSelectedEnrollmentPlace}
                        onGameLevelSelect={setSelectedEnrollmentGameLevel}
                        onHostLevelSelect={setSelectedEnrollmentHostLevel}
                        onDateSelect={setSelectedEnrollmentDate}
                    />
                );
                return (
                    <Schedule
                        title={`Игры ${enrollmentScheduleTitle}`}
                        games={enrollmentGames}
                        currentCity={currentCity}
                        dateSelector={dateSelector}
                        showEmptyGameList
                        showEnrollmentLink
                        showPlacesLeftInfo
                        showLoginForAnonymous
                        showFormula
                        isGamesList
                        onGameChange={refreshSchedule}
                    />
                );
            case "archived_games":
                const gameTypeSelector = (
                    <ScheduleGameTypeSelector
                        gameTypes={enrollment_game_types}
                        selectedGameType={selectedArchiveGameType}
                        city={currentCity}
                        onSelect={setSelectedArchiveGameType}
                    />
                );
                return (
                    <Schedule
                        title="Архив игр"
                        games={archivedGames}
                        currentCity={currentCity}
                        dateSelector={gameTypeSelector}
                        isArchive
                        isGamesList
                        showFormula
                    />
                );
            case "partners":
                return <Partners partners={partners} />;
            case "reviews":
                return <Reviews reviews={reviews} />;
            case "enrollment_form":
                return <HomeEnrollment />;
            case "contacts":
                return <HomeContacts contacts={contacts} snippets={snippets} />;
            case "custom":
                return <HomeCustomBlock data={home_custom_block} />;
            case "dronov_cup":
                return <HomeDronovCup leaders={dronov_cup_leaders} snippets={snippets} />;
            case "spacer":
                return <div style={{ height: "10px", minHeight: "10px" }}></div>;
        }
        return null;
    };

    return (
        <Layout pageClass="page-home" isDarkHeader {...layoutProps}>
            <Head>
                <title>{layoutProps.settings.SITE_NAME}</title>
                <meta
                    name="description"
                    content={`CASHFLOW (Денежный поток) - настольная игра для компании 3-10 человек. Мы проводим игровые встречи в ${currentCity.name_prepositional}, на которых вы сможете улучшить навыки финансовой грамотности, отлично провести вечер и завести новые знакомства в неформальной обстановке`}
                />
            </Head>
            {home_blocks.map((block, idx) => (
                <RenderBlock block={block} key={idx} />
            ))}
        </Layout>
    );
}

export async function getServerSideProps({ req, res, query }) {
    const cookies = (req && req.cookies) || {};
    const response = await fetchHomePageProps(
        query.city,
        query.date,
        query.place,
        query.game || query.game_type,
        query.game_level,
        cookies
    );

    const errorCode = response.ok ? false : response.status;
    if (errorCode) {
        res.statusCode = errorCode;
    }
    const props = await response.json();

    if (props.redirect) {
        return {
            redirect: {
                destination: props.redirect,
                permanent: false,
            },
        };
    }

    const {
        enrollment_game_types,
        enrollment_places,
        enrollment_game_levels,
        enrollment_host_levels,
        initialSelectedGameType,
        initialPlace,
        initialGameLevel,
        initialSelectedHostLevel,
        initialSelectedDate,
    } = getEnrollmentGamesInitialData(props, query.game || query.game_type, query.date, query.place, query.game_level);

    return {
        props: {
            ...props,
            enrollment_game_types,
            enrollment_places,
            enrollment_game_levels,
            enrollment_host_levels,
            initialSelectedGameType,
            initialSelectedHostLevel,
            initialSelectedDate,
            initialPlace,
            initialGameLevel,
            errorCode,
        },
    };
}
